import React, {
    Fragment,
    useState
  } from 'react'  
import update from 'immutability-helper'
import {
    Card,
    TextField,
    Popover,
    InlineError,
    ColorPicker,
    FormLayout,
    RangeSlider,
    Stack,
  } from "@shopify/polaris"
import { throttle } from 'lodash'
function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}
function BodyTab(props) {
    const { shop, state, setState, productPickerShow, error } = props;
    const [offerBackgroundColorPopoverVisible, setOfferBackgroundColorPopoverVisible] = useState(false)
    const [offerBackgroundColorHsl, setOfferBackgroundColorHsl] = useState({
        hue: 0,
        saturation: 0,
        brightness: 100,
        alpha: 1,
      })
    const [titleColorPopoverVisible, setTitleColorPopoverVisible] = useState(false)
    const [titleColorHsl, setTitleColorHsl] = useState({
          hue: 0,
          saturation: 0,
          brightness: 0,
          alpha: 1,
        })
    const [descriptionColorPopoverVisible, setDescriptionColorPopoverVisible] = useState(false)
    const [descriptionColorHsl, setDescriptionColorHsl] = useState({
              hue: 0,
              saturation: 0,
              brightness: 0,
              alpha: 1,
            })
    const offerBackgroundColorActivatorButton = (
        <TextField 
          label="Background color" 
          onFocus={() => setOfferBackgroundColorPopoverVisible(true)} 
          value={state.bodyBackgroundColor}
          onChange={(val) => setState(update(state, { bodyBackgroundColor: { $set: val }})) }
          connectedRight={
            <div 
              style={{ 
                borderRadius: 5,
                cursor: 'pointer',
                height: '100%', 
                width: 40, 
                backgroundColor: state.bodyBackgroundColor 
              }}
              onClick={() => setOfferBackgroundColorPopoverVisible(true)} 
            />
          }
        />
      )
      const onOfferBackgroundColorChange = (updatedColor) => {
        const color = {
          hue: updatedColor.hue || offerBackgroundColorHsl.hue || 0,
          saturation: updatedColor.saturation || offerBackgroundColorHsl.saturation || 0,
          brightness: updatedColor.brightness || offerBackgroundColorHsl.brightness || 0,
          alpha: 1,
        }
        const hex = hslToHex(
          color.hue, 
          color.saturation * 100, 
          color.brightness * 100,
        )
    
        console.log('Color changed: ', color, hex)
    
        setOfferBackgroundColorHsl(color)
        setState(update(state, { bodyBackgroundColor: { $set: hex }})) 
      }
    const onOfferBackgroundColorChangeThrottled = throttle(onOfferBackgroundColorChange, 250)

    const onTitleColorChange = (updatedColor) => {
        const color = {
          hue: updatedColor.hue || offerBackgroundColorHsl.hue || 0,
          saturation: updatedColor.saturation || offerBackgroundColorHsl.saturation || 0,
          brightness: updatedColor.brightness || offerBackgroundColorHsl.brightness || 0,
          alpha: 1,
        }
        const hex = hslToHex(
          color.hue, 
          color.saturation * 100, 
          color.brightness * 100,
        )
    
        setTitleColorHsl(color)
        setState(update(state, { titleColor: { $set: hex }})) 
      }
    const onTitleColorChangeThrottled = throttle(onTitleColorChange, 250)
    const onDescriptionColorChange = (updatedColor) => {
        const color = {
          hue: updatedColor.hue || offerBackgroundColorHsl.hue || 0,
          saturation: updatedColor.saturation || offerBackgroundColorHsl.saturation || 0,
          brightness: updatedColor.brightness || offerBackgroundColorHsl.brightness || 0,
          alpha: 1,
        }
        const hex = hslToHex(
          color.hue, 
          color.saturation * 100, 
          color.brightness * 100,
        )
    
        setDescriptionColorHsl(color)
        setState(update(state, { descriptionColor: { $set: hex }})) 
      }
    const onDescriptionColorChangeThrottled = throttle(onDescriptionColorChange, 250)
    const titleColorActivatorButton = (
        <div style={{ 
              borderRadius: 5,
              cursor: 'pointer',
              height: 36, 
              width: 40, 
              backgroundColor: state.titleColor 
            }}
            onClick={() => setTitleColorPopoverVisible(true)}>
            </div>
    )
    const descriptionColorActivatorButton = (
        <div style={{ 
              borderRadius: 5,
              cursor: 'pointer',
              height: 36, 
              width: 40, 
              backgroundColor: state.descriptionColor 
            }}
            onClick={() => setDescriptionColorPopoverVisible(true)}>
            </div>
    )
    const suffixStyles = {
        minWidth: '24px',
        textAlign: 'right',
      };
    return (
              <FormLayout>
                <RangeSlider
                label="Popup border radius"
                prefix={<p>px</p>}
                suffix={<p style={suffixStyles}>{state.popupBorderRadius}</p>}
                value={state.popupBorderRadius}
                onChange={(val) => setState(update(state, { popupBorderRadius: { $set: val }}))}
                output
                />
                 <Stack wrap={false} alignment="trailing">
                 <Stack.Item fill>
                  <TextField 
                    label="Offer Product Title" 
                    placeholder="Override the default product title" 
                    onChange={(val) => setState(update(state, { title: { $set: val }})) }
                    value={state.title}
                  />
                  </Stack.Item>
                  <Stack.Item>
                    <Popover
                    active={titleColorPopoverVisible}
                    activator={titleColorActivatorButton}
                    onClose={() => setTitleColorPopoverVisible(false)}
                    sectioned
                    >
                    <FormLayout>
                        <TextField 
                            label="Product title color" 
                            value={state.titleColor}
                            onChange={(val) => setState(update(state, { titleColor: { $set: val }})) }
                        />
                        <ColorPicker 
                        color={titleColorHsl}
                        onChange={onTitleColorChangeThrottled}
                        />
                    </FormLayout>
                    </Popover>
                  </Stack.Item>
                  </Stack>
                  <Stack wrap={false} alignment="trailing">
                  <Stack.Item fill>
                    <TextField 
                        label="Product description" 
                        placeholder="Add a product description" 
                        onChange={(val) => setState(update(state, { description: { $set: val }})) }
                        value={state.description}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <Popover
                    active={descriptionColorPopoverVisible}
                    activator={descriptionColorActivatorButton}
                    onClose={() => setDescriptionColorPopoverVisible(false)}
                    sectioned
                    >
                    <FormLayout>
                        <TextField 
                            label="Description color" 
                            value={state.descriptionColor}
                            onChange={(val) => setState(update(state, { descriptionColor: { $set: val }})) }
                        />
                        <ColorPicker 
                        color={descriptionColorHsl}
                        onChange={onDescriptionColorChangeThrottled}
                        />
                    </FormLayout>
                    </Popover>
                  </Stack.Item>
                  </Stack>
                  <Popover
                    active={offerBackgroundColorPopoverVisible}
                    activator={offerBackgroundColorActivatorButton}
                    onClose={() => setOfferBackgroundColorPopoverVisible(false)}
                    >
                    <ColorPicker 
                    color={offerBackgroundColorHsl}
                    onChange={onOfferBackgroundColorChangeThrottled}
                    />
                </Popover>
              </FormLayout>
    )
}

export default BodyTab;